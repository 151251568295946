/* navigation.module.css */

.navigationContainer {
  position: relative;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-right: auto; /* Push the logo and company name to the left */
}

.logoImage {
  width: 40px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  margin-right: 10px; /* Adjust the margin as needed */
}

.companyName {
  font-weight: bold;
  font-size: 1.2rem; /* Adjust the font size as needed */
  color: #333; /* Adjust the color as needed */
}

.navigation {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
  padding: 10px;
}

.navigationListItem {
  margin-left: 10px; /* Adjust as needed for spacing between links */
}

.navigationListItemAnchor {
  text-decoration: none;
  color: #333; /* Adjust the color as needed */
  font-weight: bold;
  transition: color 0.3s ease; /* Add a smooth transition effect */
}

.navigationListItemAnchor:hover {
  color: skyblue; /* Sky blue color on hover */
}

.hamburgerButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%; /* Make it circular */
}

.hamburgerButton:hover {
  color: skyblue; /* Sky blue color on hover */
}

.dropdownMenu {
  position: absolute;
  top: 100%; /* Position it below the hamburger icon */
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
}
