/* signoutButton.css */

.signOutButton {
    background-color: #f44336; /* Red background color */
    color: #fff; /* White text color */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
  }
  
  .signOutButton:hover {
    background-color: #d32f2f; /* Darker red color on hover */
  }
  