/* SignUp.css */

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .sign-up-form {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .sign-up-form h1 {
    color: skyblue; /* Add sky blue text color */
    margin-bottom: 20px;
  }
  
  .sign-up-form input,
  .sign-up-form label,
  .sign-up-form button {
    width: 100%;
    padding: 8px;
    margin-top: 8px;
    box-sizing: border-box;
  }
  
  .sign-up-form label {
    display: block;
    margin-top: 10px;
    color: #555;
  }
  
  .sign-up-form button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sign-up-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .sign-up-form p {
    color: #d9534f;
    margin-top: 10px;
  }
  