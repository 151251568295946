/* ... (existing styles) */

.landingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.landingContent {
  text-align: center;
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto;
  padding: 20px;
}

.landingTitle {
  color: #28ad81;
  font-size: 2rem;
  margin-bottom: 10px;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.card {
  flex: 1;
  max-width: 300px; /* Adjust the max-width as needed */
  margin: 10px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.primaryCard {
  border: 2px solid #007bff;
}

.secondaryCard {
  border: 2px solid #28a745;
}

.universityCard {
  border: 2px solid #d9534f;
}

.card h2 {
  color: #333;
}

.card p {
  color: #555;
}

.landingSubtitle {
  font-size: 1.2rem;
  color: #3a95c9; /* Adjust the color as needed */
  margin-bottom: 20px;
}

/* Add or modify the following styles for the copyright section */
.copyright {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #3a95c9; /* Background color for the copyright section */
  color: #fff; /* Text color for the copyright text */
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
}

.copyright a {
  color: #fff; /* Set link color */
  text-decoration: none;
}

.copyright a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .copyright {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}