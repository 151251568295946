.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff; /* White background */
}

.sign-in-form {
  text-align: center;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.sign-in-form h1 {
  color: #0077b5; /* Twitter color palette */
  margin-bottom: 20px;
}

.sign-in-form label {
  display: block;
  margin-top: 10px;
  color: #555;
}

.sign-in-form input {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.sign-in-form input:focus {
  border: 2px solid #0077b5;
}

.sign-in-form button {
  background-color: #0077b5;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.sign-in-form button:hover {
  background-color: #005685; /* Darker shade on hover */
}

.sign-in-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.sign-in-form p {
  color: #d9534f;
  margin-top: 10px;
}

.additional-links {
  margin-top: 20px;
}